import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../Common/CommonCallApi";
import { setFeaturesArray } from "../../store/Slices/featureSlice";
function Sidebar() {
  const dispatch = useDispatch();
  console.log("hello");
  // const roles=useSelector((state)=>state.featureSlice.featuresArraylist)
  const { featuresArray } = useSelector((state) => state.featureSlice);

  const [tabSelect, setTabSelect] = useState("");
  const data = useLocation();
  console.log(data.pathname);
  let SidebarTabs;
  let FilteredTabs = SidebarTabs;

  if (data.pathname.includes("secondarySales")) {
    SidebarTabs = [
      {
        name: "Dashboard",
        route: "/secondarySales/dashboard",
        icon: "mdi mdi-home-account",
        children: [],
      },

      {
        name: "Area Violations",
        icon: "mdi mdi-cash",
        children: [
          {
            name: "Area Violations",
            route: "/secondarySales/violations",
          },
          {
            name: "Area Violations Draft",
            route: "/secondarySales/violationsDrafts",
          },
        ],
      },

      // {
      //   name: "Area Violations",
      //   route: "/secondarySales/violations",
      //   icon: "mdi mdi-map-marker-path",
      //   children: [],
      // },
      // {
      //   name: "Area Violations Draft",
      //   route: "/secondarySales/violationsDrafts",
      //   icon: "mdi mdi-map-marker-path",
      //   children: [],
      // },

      // },
      {
        name: "Credit Management",
        icon: "mdi mdi-cash",
        children: [
          {
            name: "Credit Assignment",
            route: "/secondarySales/creditAssignment",
          },
          {
            name: "Credit Collection",
            route: "/secondarySales/creditCollection",
          },
          {
            name: "Cheque Payments",
            route: "/secondarySales/chequePayments",
          },
        ],
      },
      {
        name: "Complaint Management",
        route: "expanded",
        icon: "mdi mdi-comment-eye",
        children: [
          {
            name: "New Complains",
            route: "/secondarySales/internalComplaint",
          },
          {
            name: "Complain Logs",
            route: "/secondarySales/externalComplaint",
          },
          {
            name: "Complain Drafts",
            route: "/secondarySales/allComplaintDraft",
          },
        ],
      },
      {
        name: "DO Management",
        route: "/secondarySales/DO_Management",
        icon: "mdi mdi-book-open-page-variant",
        children: [
          { name: "DO List", route: "/secondarySales/doList" },
          { name: "Sales Planning", route: "/secondarySales/salesPlanning" },
          { name: "DO Request", route: "/secondarySales/doRequest" },
          {
            name: "Create External DO",
            route: "/secondarySales/requestExternalDo",
          },
        ],
      },
      {
        name: "Rebate Management",
        route: "/secondarySales/rebate",
        icon: "mdi mdi-book-open-page-variant",
        children: [
          // {name:"Rebate" ,route:"/secondarySales/rebate"},
          // {name:"Trading Offer" ,route:"/secondarySales/tradingoffer"}
        ],
      },
      {
        name: "Expense Management",
        route: "expanded",
        icon: "mdi mdi-account-cash",
        children: [
          { name: "Transaction", route: "/secondarySales/transaction" },
          { name: "Category", route: "/secondarySales/category" },
          { name: "Subcategory", route: "/secondarySales/subcategory" },
        ],
      },
      {
        name: "Performance",
        route: "expanded",
        icon: "mdi mdi-account-cash",
        children: [
          { name: "Employees", route: "/secondarySales/employees" },
          { name: "Distributors", route: "/secondarySales/distributors" },
          { name: "Outlets", route: "/secondarySales/outlet" },
        ],
      },
      // {
      //   name: "Assing Targets",
      //   route: "/ssp/assigned",
      //   icon: "mdi mdi-home-account",
      //   children: [],
      // },

      // {
      //   name: "Complaint Management",
      //   route: "expanded",
      //   icon: "mdi mdi-home-account",
      //   children: [
      //     {
      //       name: "InternalComplaint",
      //       route: "/secondarySales/internalComplaint",
      //     },
      //     {
      //       name: "ExternalComplaint",
      //       route: "/secondarySales/externalComplaint",
      //     },
      //   ],

      // {
      //   name: "Expense Management",
      //   route: "expanded",
      //   icon: "mdi mdi-home-account",
      //   children: [{ name: "SSPExpense", route: "/ssp/sspexpense" }],
      // },

      {
        name: "Global Approvals",
        route: "/secondarySales/globalApprovals",
        icon: "mdi mdi-home-account",
        children: [],
      },
      {
        name: "Market Intel",
        route: "expanded",
        icon: "mdi mdi-train-car",
        children: [
          { name: "Market Intelligence", route: "/secondarySales/marketIntel" },
          {
            name: "Market Intelligence Draft",
            route: "/secondarySales/marketIntelDraft",
          },
        ],
      },

      {
        name: "Target Assignment",
        route: "/secondarySales/targetAssignment",
        icon: "mdi mdi-home-account",
        children: [],
      },

      // {
      //   name: "Market Intelligence Draft",
      //   route: "/secondarySales/marketIntelDraft",
      //   icon: "mdi mdi-account-tie",
      //   children: [],
      // },
      // {
      //   name: "Market Intelligence",
      //   route: "/secondarySales/marketIntel",
      //   icon: "mdi mdi-account-tie",
      //   children: [],
      // },
      // {
      //   name: "Performance Management",
      //   route: "/secondarySales/performance",
      //   icon: "mdi mdi-home-account",
      //   children: [],
      // },
      {
        name: "Market Visits",
        route: "expanded",
        icon: "mdi mdi-train-car",
        children: [
          { name: "Journeys", route: "/secondarySales/journeys" },

          { name: "Journey Logs", route: "/secondarySales/journeyLogs" },
          {
            name: "Assign Dates",
            route: "/secondarySales/assignPriorities",
          },
          {
            name: "Market Visit Details",
            route: "/secondarySales/marketVisit",
          },
        ],
      },

      {
        name: "Order Management",
        route: "expanded",
        icon: "mdi mdi-package-variant-closed",
        children: [
          { name: "Pending Order", route: "/secondarySales/pendingOrder" },
          { name: "Order Logs", route: "/secondarySales/orderLogs" },
          { name: "Assign DO", route: "/secondarySales/assignDo" },

          {
            name: "Dispatch Management",
            route: "/secondarySales/dispatchManagement",
          },
        ],
      },
      {
        name: "Reports",
        route: "/secondarySales/reports",
        icon: "mdi mdi-home-account",
        children: [],
      },

      {
        name: "Sales Universe",
        route: "expanded",
        icon: "mdi mdi-earth",
        children: [
          { name: "All Outlet", route: "/secondarySales/allOutlet" },
          { name: "Verified Outlet", route: "/secondarySales/verifiedOutlet" },
          { name: "Pending Outlet", route: "/secondarySales/pendingOutlet" },
          // { name: "Pending Outlet", route: "/secondarySales/pendingOutlet" },

          // { name: "Outlet Details", route: "/secondarySales/OutletDetails" },
          { name: "Outlet Draft", route: "/secondarySales/outletDraft" },

          { name: "GIS Overview", route: "/secondarySales/gisOverview" },
        ],
      },
    ];
  } else if (data.pathname.includes("institutionalSales")) {
    SidebarTabs = [
      {
        name: "Dashboard",
        route: "/institutionalSales/dashboard",
        icon: "mdi mdi-home-account",
        children: [],
      },
      {
        name: "Lead Management",
        route: "/institutionalSales/leadManagement",
        icon: "mdi mdi-home-account",
        children: [],
      },
      {
        name: "Customer Management",
        route: "/institutionalSales/customerManagement",
        icon: "mdi mdi-home-account",
        children: [],
      },
      {
        name: "Prospect Management",
        route: "/institutionalSales/prospectManagement",
        icon: "mdi mdi-home-account",
        children: [],
      },
      {
        name: "3 C's Management",
        route: "/institutionalSales/3C'sManagement",
        icon: "mdi mdi-home-account",
        children: [],
      },
    ];
  } else {
    SidebarTabs = [
      //for Single One
      {
        name: "Dashboard",
        route: "/systemAdmin/dashboard",
        icon: "mdi mdi-home-account",
        children: [],
      },

      //for Nested
      {
        name: "Area Management",
        icon: "mdi mdi-chart-areaspline-variant",
        route: "expanded",
        children: [
          { name: "Country", route: "/systemAdmin/country" },

          { name: "Region", route: "/systemAdmin/region" },

          { name: "City", route: "/systemAdmin/city" },

          { name: "Zone", route: "/systemAdmin/zone" },

          { name: "SubZone", route: "/systemAdmin/subZone" },
          { name: "Area", route: "/systemAdmin/area" },
          { name: "SubLocality", route: "/systemAdmin/subLocality" },
        ],
      },

      {
        name: "Brand Management",
        icon: "mdi mdi-watermark",
        route: "expanded",
        children: [
          { name: "Brand", route: "/systemAdmin/brands" },
          { name: "Brand Type", route: "/systemAdmin/brandTypes" },
        ],
      },
      {
        name: "Company Setup",
        icon: "mdi mdi-cog",
        route: "expanded",
        children: [
          {
            name: "Company Information",
            route: "/systemAdmin/companyStructure",
          },
          { name: "Department", route: "/systemAdmin/department" },
          { name: "Sub Department", route: "/systemAdmin/subDepartment" },
          { name: "Designation", route: "/systemAdmin/designation" },
          { name: "Grades", route: "/systemAdmin/grades" },

       
        ],
      },
      {
        name: "Distributor Setup",
        icon: "mdi mdi-store",
        route: "expanded",
        children: [
          { name: "Distributor Management", route: "/systemAdmin/distributor" },

          { name: "Distributor Channels", route: "/systemAdmin/channels" },
          { name: "Outlet", route: "/systemAdmin/outlet" },
          // { name: "GIS Outlet", route: "/systemAdmin/GISOutlet" },

          { name: "Assign Outlet", route: "/systemAdmin/assignOutlet" },
        ],
      },
      {
        name: "Users Management",
        icon: "mdi mdi-account-group",
        route: "expanded",
        children: [
          { name: "Employee", route: "/systemAdmin/employees" },
          { name: "Locked User", route: "/systemAdmin/unlockUser" },
          { name: "Role And Permissions", route: "/systemAdmin/role" },
          { name: "Users", route: "/systemAdmin/user" },
          { name: "User Group", route: "/systemAdmin/userGroup" },
        ],
      },

      {
        name: "Plant",
        route: "/systemAdmin/plants",
        icon: "mdi mdi-factory",
        children: [],
      },
      {
        name: "ISP LOV's",
        icon: "mdi mdi-account-group",
        route: "expanded",
        children: [
          { name: "Lead Sources", route: "/systemAdmin/leadsource" },
          { name: "Project Nature", route: "/systemAdmin/projectnature" },
          { name: "Authorities", route: "/systemAdmin/authorities" },
          { name: "Funding Agencies", route: "/systemAdmin/fundingagencies" },
          { name: "Project Segments", route: "/systemAdmin/projectsegments" },
          { name: "Remarks", route: "/systemAdmin/remarks" },
          { name: "Interaction Types", route: "/systemAdmin/interactiontypes" },
          { name: "Status", route: "/systemAdmin/status" },
        ],
      },
      // {
      //   name: "Lead Sources",
      //   route: "/systemAdmin/leadsource",
      //   icon: "mdi mdi-factory",
      //   children: [],
      // },

      // {
      //   name: "Project Nature",
      //   route: "/systemAdmin/projectnature",
      //   icon: "mdi mdi-factory",
      //   children: [],
      // },
      // {
      //   name: "Authorities",
      //   route: "/systemAdmin/authorities",
      //   icon: "mdi mdi-factory",
      //   children: [],
      // },
      // {
      //   name: "Funding Agencies",
      //   route: "/systemAdmin/fundingagencies",
      //   icon: "mdi mdi-factory",
      //   children: [],
      // },
      // {
      //   name: "Project Segments",
      //   route: "/systemAdmin/projectsegments",
      //   icon: "mdi mdi-factory",
      //   children: [],
      // },
      // {
      //   name: "Remarks",
      //   route: "/systemAdmin/remarks",
      //   icon: "mdi mdi-factory",
      //   children: [],
      // },
      // {
      //   name: "Interaction Types",
      //   route: "/systemAdmin/interactiontypes",
      //   icon: "mdi mdi-factory",
      //   children: [],
      // },
      // {
      //   name: "Status",
      //   route: "/systemAdmin/status",
      //   icon: "mdi mdi-factory",
      //   children: [],
      // },

      {
        name: "Bulk Update",
        route: "/systemAdmin/bulkupdate",
        icon: "mdi mdi-update",
        children: [],
      },
      {
        name: "Import Export Widget",
        route: "/systemAdmin/importExportWidget",
        icon: "mdi mdi-update",
        children: [],
      },
      {
        name: "Notifications",
        route: "/systemAdmin/notifications",
        icon: "mdi mdi-bell-badge-outline",
        children: [],
      },
      {
        name: "Warehouse",
        route: "/systemAdmin/warehouse",
        icon: "mdi mdi-warehouse",
        children: [],
      },
      // {
      //   name: "Modules",
      //   route: "/systemAdmin/modules",
      //   icon: "mdi mdi-view-module",
      //   children: [],
      // },

      {
        name: "Product Management",
        icon: "mdi mdi-package-variant-closed",
        route: "expanded",
        children: [
          { name: "Launched Products", route: "/systemAdmin/productLaunch" },
          { name: "Product", route: "/systemAdmin/product" },
          { name: "Product Types", route: "/systemAdmin/productTypes" },
        ],
      },

      {
        name: "System Logs",
        route: "/systemAdmin/logs",
        icon: "mdi mdi-view-module",
        children: [],
      },
      {
        name: "Sales Management",
        icon: "mdi mdi-package-variant-closed",
        route: "expanded",
        children: [
          { name: "Price Agreement", route: "/systemAdmin/tradeagreement" },
          { name: "Sales Group", route: "/systemAdmin/salesGroup" },
        ],
      },

      {
        name: "Work Flow Management",
        icon: "mdi mdi-chart-areaspline-variant",
        route: "expanded",
        children: [
          { name: "DOC Type", route: "/systemAdmin/doctype" },
          { name: "Workflow", route: "/systemAdmin/workflow" },
        ],
      },

      // {
      //   name: "Flow Data",
      //   route: "/flowData",
      //   icon: "mdi mdi-factory",
      //   children: [],
      // },
    ];
  }

  if (featuresArray == "is_superuser") {
    FilteredTabs = SidebarTabs;
    console.log(FilteredTabs, "FilteredTabs");
  } else if (featuresArray != undefined && featuresArray.length != 0) {
    const names = featuresArray?.features_list.map(
      (index) => index?.feature.name
    );

    FilteredTabs = filterSidebarTabs(names);
    console.log(FilteredTabs, "names");
  }
  function filterSidebarTabs(namesArray) {
    return SidebarTabs.map((tab) => {
      if (tab.children.length > 0) {
        const filteredChildren = tab.children.filter((child) =>
          namesArray.includes(child.name)
        );
        if (filteredChildren.length > 0) {
          return { ...tab, children: filteredChildren };
        }
      } else if (namesArray.includes(tab.name)) {
        return tab;
      }
      return null;
    }).filter((tab) => tab !== null);
  }

  const handleActive = (index) => {
    const tabSelectValue = tabSelect.includes(index);

    if (tabSelectValue) {
      const newTAB = tabSelect.filter((tab) => tab != index);
      setTabSelect(newTAB);
    } else {
      setTabSelect((prev) => [...prev, index]);
    }
  };
  return (
    <div className="vertical-menu">
      <div
        id="sidebar-menu"
        style={{ fontFamily: "IBM Plex Sans" }}
        className="mm-active"
      >
        <ul
          className="metismenu list-unstyled mm-show mm-active scrollbar"
          id="side-menu"
        >
          {FilteredTabs != undefined &&
            FilteredTabs.map((index, key) => {
              return index?.children.length != 0 ? (
                <li
                  key={key}
                  className={tabSelect.includes(index.name) ? "mm-active" : ""}
                >
                  <a
                    className="has-arrow"
                    aria-expanded="true"
                    onClick={() => handleActive(index.name)}
                  >
                    <i
                      className={index.icon}
                      style={{ marginRight: "10px" }}
                    ></i>
                    <span>{index.name}</span>
                  </a>

                  <ul
                    className={
                      tabSelect.includes(index.name)
                        ? "sub-menu mm-collapse mm-show"
                        : "sub-menu mm-collapse"
                    }
                  >
                    {index?.children.map((childIndex, index) => {
                      return (
                        <li key={index}>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                            to={childIndex.route}
                          >
                            {childIndex.name}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ) : (
                <li key={key} style={{ cursor: "pointer" }}>
                  <NavLink to={index.route} className="">
                    <i
                      className={index.icon}
                      style={{ marginRight: "10px" }}
                    ></i>
                    <span>{index.name}</span>
                  </NavLink>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
