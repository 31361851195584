import AxiosService from "./HttpService";

export const loadOptions = async (searchTerm, uri) => {
  const HttpService = new AxiosService();
  try {
    const { data } = await HttpService.get(uri, {
      params: {
        search: searchTerm,
        is_active: "ACTIVE",
      },
    });
    return data?.data?.data?.map((item) => ({
      value: item?.id || item?.guid,
      label: item?.name || item?.first_name + " " + item?.last_name,
      email: item?.email || "",
    }));
  } catch (error) {
    console.log("error", error);
    return;
  }
};
export const loadUserOptions = async (searchTerm, uri, search_by) => {
  const HttpService = new AxiosService();
  try {
    const { data } = await HttpService.get(uri, {
      params: {
        search: searchTerm,
        allow_login: true,
      },
    });

    return data?.data?.data?.map((item) => ({
      value: item?.id || item?.guid,
      label:
        search_by === "name"
          ? item?.name ||
            item?.first_name +
              " " +
              item?.last_name +
              ` (${item?.department?.name}, ${item?.designation?.name})`
          : item?.email || "",
      email: item?.email || "",
      name: item?.name || item?.first_name + " " + item?.last_name || "",
      contact_number: item?.contact_number || "",
    }));
  } catch (error) {
    console.log("error", error);
    return;
  }
};
export const NewloadUserOptions = async (searchTerm, uri, search_by) => {
  const HttpService = new AxiosService();
  try {
    const { data } = await HttpService.get(uri, {
      params: {
        search: searchTerm,
        allow_login: false,
      },
    });

    return data?.data?.data?.map((item) => ({
      value: item?.id || item?.guid,
      label:
        search_by === "name"
          ? item?.name ||
            item?.first_name +
              " " +
              item?.last_name +
              ` (${item?.department?.name}, ${item?.designation?.name})`
          : item?.email || "",
      email: item?.email || "",
      name: item?.name || item?.first_name + " " + item?.last_name || "",
      contact_number: item?.contact_number || "",
    }));
  } catch (error) {
    console.log("error", error);
    return;
  }
};
export const loadUserOptionsWithoutLogin = async (
  searchTerm,
  uri,
  search_by
) => {
  const HttpService = new AxiosService();
  try {
    const { data } = await HttpService.get(uri, {
      params: {
        search: searchTerm,
      },
    });
    console.log(data, "what data is in doctype");
    return data?.data?.data?.map((item) => ({
      value: item?.id || item?.guid,
      label:
        search_by === "name"
          ? item?.name ||
            item?.first_name +
              " " +
              item?.last_name +
              ` (${item?.department?.name}, ${item?.designation?.name})`
          : item?.email || "",
      email: item?.email || "",
      name: item?.name || item?.first_name + " " + item?.last_name || "",
      contact_number: item?.contact_number || item?.owner_contact || "",
      id: item?.id || "",
      action: item?.form_name || "",
      feature: item?.module?.name || "",
      contact: item?.contact || "",
      adress: item?.adress || "",
    }));
  } catch (error) {
    console.log("error", error);
    return;
  }
};
export const loadOneTimeOptions = async (
  uri,
  setLoading = () => {},
  setData = () => {}
) => {
  const HttpService = new AxiosService();
  debugger;
  setLoading(true);

  try {
    const { data } = await HttpService.get(uri, {
      params: {
        is_active: "ACTIVE",
      },
    });
    console.log(data, "data");
    setLoading(false);
    setData(
      data?.data?.data?.map((item) => ({
        value: item?.id || item?.guid,
        label: item?.name || item?.first_name + " " + item?.last_name,
      }))
    );

    console.log({ data });
    // return data?.data?.data?.map((item) => ({
    //   value: item?.id || item?.guid,
    //   label: item?.name || item?.first_name + " " + item?.last_name,
    // }));
  } catch (error) {
    // console.error("Error loading options:", error);
    setLoading(false);
    // throw error;
  }
};
